import {
  onMounted,
  Ref,
  computed,
  ref,
  toRaw,
  unref,
  watch,
  reactive,
  MaybeRefOrGetter,
  toValue,
  ComputedRef,
} from 'vue';
import {
  CurrentV7Request,
  MetricV7Request,
  ObjectV7Request,
  ScatterV7Request,
  TableV7Request,
  TopologyV7Request,
  TagsRequest,
  TagsRequestTagType,
  TopologyV7RequestAggregationType,
  Nodes,
} from '@/openapi/data/model';
import { DashboardCommand, useDashboardCommand } from '@/worker/composables/useDashboardCommand';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import {
  CUSTOM_KEY,
  FIXED_DATA_ID,
  TIME_RANGE_COUNT_BY_CHART_TYPE,
  WidgetCommonArgument,
  WIDGET_CHART_TYPE_KEY,
  WIDGET_TIME_PERIOD,
  DISPLAY_STYLE_KEY,
} from '@/dashboard/utils/define';
import { BY_TARGET_SERIES_TYPE } from '@/common/define/widget.define';
import {
  Axes,
  ChartData,
  ChartOption,
  ColumnWithHideInfo,
  CustomColumn,
  FilterUUID,
  SeriesData,
  TimePeriodType,
  WidgetChartDataStatusInfo,
  WidgetTriggerPathType,
  WidgetUUID,
} from '@/common/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import {
  generateUUID,
  getAPIErrorStatusText,
  getDataArrangeIntervalByTimeRange,
  getUtcTimeFromTzTime,
  standardTimeToUtcZeroTime,
} from '@/common/utils/commonUtils';
import { isEmpty, isEqual, uniq, uniqBy } from 'lodash-es';
import type {
  WidgetProps as Props,
  WidgetEmit as Emit,
  ChartRawData,
  WidgetChartDataWithIndex,
} from '@/dashboard/components/widgets/widgets.types';
import {
  CalendarTimeRange,
  DisplayStyleType,
  Interpolation,
  WidgetChartType,
  WidgetCreateType,
  WidgetIntervalType,
  WidgetModeType,
  WidgetTarget,
  WidgetTimePeriod,
} from '@/dashboard/utils/types';
import { useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import { storeToRefs } from 'pinia';
import { CHART_COLORS_SPARROW } from '@/common/utils/define';
import { TableRawData, ColumnInfo } from '@/worker/commands/dashboard/table';
import {
  EqualizerChartData,
  EqualizerChartSeriesData,
  MonitoringTargetStatus,
} from '@/common/components/molecules/equalizerChart/core/equalizerChart.types';
import {
  getDefaultSeriesColors,
  getDefaultSeriesColor,
  getChartDataStatusById,
  isCustomStatId,
  decodeArgParam,
} from '@/dashboard/utils/dashboardUtils';
import { useStatInfoStore } from '@/common/stores/stat-info';
import {
  useEvChartStyle,
  getSafeColorListByTheme,
  getCustomPeriodInterval,
} from '@/common/utils/chartUtils';
import { formatYAxisWithUnit, useEvChartTooltipFormatter } from '@/common/utils/chartUnit.utils';
import { PieChartItem } from '@/common/components/molecules/pieChartGroup/pieChartGroup.setup';
import { TriggerDetailInfo, useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { EqualizerClickEventValue } from '@/common/components/molecules/equalizerChart/equalizerChart.setup';
import { TargetTriggerInfo } from '@/dashboard/components/widgets/widgets.types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { formatTimeRangeToUtc } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { convertValueWithUnit } from '@/common/utils/convertUnits.utils';
import { useWidgetClickInject, useDataFieldInject } from '@/dashboard/context';
import { useFilterSearchStore } from '@/dashboard/stores/filter-search';
import { useWidgetRelationStore } from '@/dashboard/stores/widget-relation';
import { TopologyRawData } from '@/worker/commands/dashboard/topology';
import { WidgetVariable } from '@/dashboard/components/widgetSettingsWindow/referenceSettingOption/variableListWindow/variable.types';
import dayjs from 'dayjs';
import { useUserEnvStore } from '@/common/stores/user-env';
import {
  DashboardUserEnvKeys,
  useDashboardUserEnv,
} from '@/common/uses/userEnv/useDashboardUserEnv';
import { useDynamicColumns } from '@/common/uses/useDynamicColumns';
import { useDashboardUserEnvStore } from '@/dashboard/stores/dashboard-user-env';
import { EqualizerChartOptionTableData } from '../widgetSettingsWindow/graphWidgetSettings/equalizerSettings/equalizerSettingsChartOption/equalizerSettingsChartOption.types';
import { DefaultPieWidgetChartOption } from '../widgetSettingsWindow/graphWidgetSettings/pieSettings/pieSettingsChartOption/pieSettingsChartOption.defines';
import { useChartDataStatus } from './checkChartDataStatus/useChartDataStatus';
import {
  convertRequestKeyType,
  convertChartType,
  getFieldList,
  appendChartDataIndex,
  isChartDataValid,
} from './widgets.utils';
import {
  CommonChartDataIndex,
  CommonStatId,
  ReferenceInfo,
} from '../widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { useWidgetArgumentList } from '../widgetSettingsWindow/widgetSettingsWindow.composables';
import { ChartDataFilter } from './widgets.defines';
import { PieWidgetProps } from './piePieWidget/piePieWidget.setup';
import { GaugeWidgetProps } from './gaugeWidget/gaugeWidget.setup';

export interface CurrentData {
  id: string;
  label: string;
  value: number;
  unit?: string;
  color: string;
  chartDataIndex?: number;
  displayName?: string;
  statName?: string;
  alias?: string;
}

export type RequestKeyType =
  | 'metricRequests'
  | 'currentRequests'
  | 'tableRequests'
  | 'objectRequests'
  | 'scatterRequests'
  | 'topologyRequests';

type RequestType =
  | MetricV7Request
  | CurrentV7Request
  | TableV7Request
  | ObjectV7Request
  | ScatterV7Request
  | TopologyV7Request;

export interface DefaultParams {
  period?: WidgetTimePeriod;
  interval?: WidgetIntervalType;
  fromTime?: string;
  toTime?: string;
  limit?: number;
}

export interface WidgetApiOptions {
  requestKey: RequestKeyType;
  method: DashboardCommand['method'];
  defaultParams?: DefaultParams;
  timeout?: number;
}

export interface RequestPeriod {
  fromTime?: string;
  toTime?: string;
  period?: TimePeriodType;
  interval?: WidgetIntervalType;
  additionalFromTime?: string;
  additionalToTime?: string;
  additionalInterval?: WidgetIntervalType;
}

export const useWidgetArgParam = (referenceInfo: ComputedRef<ReferenceInfo>) => {
  const { getFilterValue } = useFilterSearchStore();
  const { clickedWidgetInfo } = useWidgetClickInject();
  const { getArgumentList } = useWidgetArgumentList();

  const getArgParamValue = (widgetUUID: WidgetUUID, filter: FilterUUID) => {
    const clickedWidgetData = clickedWidgetInfo.value?.get(widgetUUID)?.get(filter);
    if (clickedWidgetData) {
      return clickedWidgetData;
    }
    return getFilterValue(widgetUUID, filter);
  };

  const extractArgParamValue = (widgetVariable: WidgetVariable | null | undefined) => {
    if (widgetVariable == null) {
      return '';
    }

    if (widgetVariable.type === 'fixedValue') {
      const isTime =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?/.test(`${widgetVariable.fixedValue}`) ||
        /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}(.\d+)?/.test(`${widgetVariable.fixedValue}`);
      return (
        (isTime
          ? standardTimeToUtcZeroTime(widgetVariable.fixedValue)
          : widgetVariable.fixedValue) ?? ''
      );
    }
    return getArgParamValue(widgetVariable.widgetId, widgetVariable.filterId) ?? '';
  };

  const getArgParams = (
    chartType: WidgetChartType,
    chartDataIndex: number,
    statId: string,
    category: string,
  ): Array<{ [key: string]: string }> | undefined => {
    const args = {};

    const commonArguments = WidgetCommonArgument[chartType];
    const commonReferenceInfo = referenceInfo.value?.[`${CommonChartDataIndex}`];
    commonArguments?.forEach((commonArgumentName) => {
      const findValue = commonReferenceInfo?.find(
        ({ key }) => key.statId === CommonStatId && key.argumentName === commonArgumentName,
      )?.value;
      args[commonArgumentName] = extractArgParamValue(findValue);
    });

    const argumentNames = getArgumentList(commonArguments, statId, category);
    argumentNames?.forEach((argumentName) => {
      const findValue = referenceInfo.value?.[`${chartDataIndex}`]?.find(
        ({ key }) => key.statId === statId && key.argumentName === argumentName,
      )?.value;
      args[argumentName] = extractArgParamValue(findValue);
    });

    return [args];
  };

  return { getArgParams };
};

export const useWidgetDataParams = () => {
  const { globalTimePeriod, selectedGlobalVariables } = storeToRefs(useGlobalFilterStore());

  const getRequestTags = (targets: WidgetTarget[]): TagsRequest[] => {
    const useGlobalVariableIds = selectedGlobalVariables.value
      .filter(({ use }) => use)
      .map(({ globalFilterId }) => `${globalFilterId}`);
    const filteredTargets = targets.filter(
      ({ tagType, tagValueId }) =>
        tagType !== 'globalVariable' ||
        (tagType === 'globalVariable' && useGlobalVariableIds.includes(tagValueId)),
    );
    const tagsMap = filteredTargets.reduce(
      (requestTagsMap: Map<string, TagsRequest>, { tagKey, tagType, tagValue }) => {
        const mapKey = `${tagKey}${tagType}`;
        if (requestTagsMap.get(mapKey)) {
          requestTagsMap.get(mapKey)?.tagValue?.push(tagValue);
        } else {
          requestTagsMap.set(mapKey, {
            tagKey: tagKey ?? '',
            tagType: tagType as TagsRequestTagType,
            tagValue: [tagValue],
          });
        }
        return requestTagsMap;
      },
      new Map(),
    );

    return Array.from(tagsMap.values());
  };

  const getRequestUserTags = (globalFilterIdList?: string[]): TagsRequest[] => {
    let useGlobalVariables = selectedGlobalVariables.value.filter(({ use }) => use);
    if (globalFilterIdList && globalFilterIdList.length > 0) {
      useGlobalVariables = useGlobalVariables.filter(({ globalFilterId }) => {
        if (globalFilterIdList == null) return true;
        return globalFilterIdList?.includes(`${globalFilterId}`);
      });
    }

    const userTagsMap = useGlobalVariables.reduce(
      (requestTagsMap: Map<string, TagsRequest>, { globalFilterId, selectedList }) => {
        if (selectedList?.length) {
          selectedList.forEach(({ groupTagId, tagId, tagType }) => {
            const mapKey = `${groupTagId}${tagType}`;
            if (requestTagsMap.get(mapKey)) {
              requestTagsMap.get(mapKey)?.tagValue?.push(tagId);
            } else {
              requestTagsMap.set(mapKey, {
                tagKey: groupTagId ?? '',
                tagType: tagType as TagsRequestTagType,
                tagValue: [tagId],
              });
            }
          });
        } else {
          requestTagsMap.set(`${globalFilterId}`, {
            tagKey: 'Global Variable',
            tagType: 'globalVariable',
            tagValue: [`${globalFilterId}`],
          });
        }

        return requestTagsMap;
      },
      new Map(),
    );

    return Array.from(userTagsMap.values());
  };

  const getFilteredTags = (
    targets: WidgetTarget[],
  ): { tags: TagsRequest[]; userTags: TagsRequest[] } => {
    const tags = getRequestTags(targets);
    const globalFilterIdList = tags.reduce<string[]>((idList, { tagType, tagValue }) => {
      if (tagType === 'globalVariable') {
        tagValue?.forEach((tagValueItem) => {
          if (tagValueItem != null) idList.push(tagValueItem);
        });
      }
      return idList;
    }, []);
    return {
      tags,
      userTags: getRequestUserTags(globalFilterIdList),
    };
  };

  const getRequestPeriodByCalendar = (
    calendarTimeRange: CalendarTimeRange[],
    chartType: WidgetChartType,
  ): RequestPeriod => {
    const timeRangeCount = TIME_RANGE_COUNT_BY_CHART_TYPE[chartType];
    if (timeRangeCount === 2) {
      const [baseTimeRange, comTimeRange] = calendarTimeRange;
      const { fromTimeUtc: baseFromTime, toTimeUtc: baseToTime } =
        formatTimeRangeToUtc(baseTimeRange);
      const { fromTimeUtc: comFromTime, toTimeUtc: comToTime } = formatTimeRangeToUtc(comTimeRange);
      const requestPeriod: RequestPeriod = {
        fromTime: baseFromTime,
        toTime: baseToTime,
        interval: getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(baseFromTime),
          toTime: +getUtcTimeFromTzTime(baseToTime),
        }),
      };

      if (comFromTime !== 'Invalid Date') {
        requestPeriod.additionalFromTime = comFromTime;
        requestPeriod.additionalToTime = comToTime;
        requestPeriod.additionalInterval = getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(comFromTime),
          toTime: +getUtcTimeFromTzTime(comToTime),
        });
      }

      return requestPeriod;
    }
    const { fromTimeUtc, toTimeUtc } = formatTimeRangeToUtc(calendarTimeRange[0]);
    return {
      fromTime: fromTimeUtc,
      toTime: toTimeUtc,
      interval: getCustomPeriodInterval('', {
        fromTime: +getUtcTimeFromTzTime(fromTimeUtc),
        toTime: +getUtcTimeFromTzTime(toTimeUtc),
      }),
    };
  };

  const getRequestPeriod = (
    timePeriod?: WidgetTimePeriod,
    chartType?: WidgetChartType,
    calendarTimeRange?: CalendarTimeRange[],
  ): RequestPeriod => {
    if (timePeriod === 'globalTime') {
      if (globalTimePeriod.value.isPaused) {
        const widgetTimePeriod = WIDGET_TIME_PERIOD[globalTimePeriod.value.timePeriod];
        return {
          fromTime: globalTimePeriod.value.fromTimeUtc,
          toTime: globalTimePeriod.value.toTimeUtc,
          interval:
            widgetTimePeriod?.interval ??
            getCustomPeriodInterval('', {
              fromTime: +getUtcTimeFromTzTime(globalTimePeriod.value.fromTimeUtc),
              toTime: +getUtcTimeFromTzTime(globalTimePeriod.value.toTimeUtc),
            }),
        };
      }
      return { period: globalTimePeriod.value.timePeriod as TimePeriodType };
    }

    if (timePeriod === 'calendar') {
      return getRequestPeriodByCalendar(calendarTimeRange!, chartType!);
    }

    return { period: (timePeriod as TimePeriodType) ?? 'p5m' };
  };

  return {
    getRequestTags,
    getRequestUserTags,
    getFilteredTags,
    getRequestPeriod,
  };
};

// NOTE: request에서 tags가 있는 경우, targetIds property는 무시됩니다.
export const assignReferenceParams = <ParamType extends RequestType>(
  param: ParamType & { id: string; argParam?: Array<{ [key: string]: string }> },
  createType: WidgetCreateType,
) => {
  if (createType === 'reference') {
    // NOTE: 커스텀 지표로 사용할 경우, targetIds가 적용되어야하기 때문에 tags값을 undefined로 만들어줘야합니다.
    const argParamFromTime = decodeArgParam(param.argParam?.at(0)?.fromTime) ?? '';
    const argParamToTime = decodeArgParam(param.argParam?.at(0)?.toTime) ?? '';
    if (!isCustomStatId(param.dataId)) {
      return {
        ...param,
        fromTime: argParamFromTime,
        toTime: argParamToTime,
        interval:
          argParamFromTime && argParamToTime
            ? getDataArrangeIntervalByTimeRange(+dayjs(argParamToTime) - +dayjs(argParamFromTime))
            : undefined,
        targetIds: param.argParam
          ?.at(0)
          ?.targetIds?.split(',')
          .map((id) => decodeArgParam(id))
          .filter((id) => !!id),
        tags: undefined,
        userTags: undefined,
        period: undefined,
        argParam: undefined,
      };
    }
    return {
      ...param,
      period: undefined,
      fromTime: argParamFromTime,
      toTime: argParamToTime,
    };
  }
  return {
    ...param,
    argParam: undefined,
  };
};

export const useChangeApiParams = <ChartType extends 'TIME_SERIES'>(
  props: Props<ChartType>,
  options: WidgetApiOptions,
) => {
  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();
  const { defaultParams = {} } = options;

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const getInterpolateType = (
    interpolation: Interpolation | undefined,
    displayType: DisplayStyleType,
  ) => {
    const displayBar = [DISPLAY_STYLE_KEY.BAR, DISPLAY_STYLE_KEY.STACKED_BAR];

    if (displayBar.includes(displayType)) {
      return 'Null';
    }

    return interpolation ?? 'Null';
  };

  const changeApiParamsWithInterpolate = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(MetricV7Request & { id: string })[]> => {
    return widgetChartData.map(
      ({ id, dataType, dataId, targets, seriesType, category, chartDataIndex }) => ({
        id,
        interval: defaultParams.interval,
        fromTime: defaultParams.fromTime,
        toTime: defaultParams.toTime,
        limit: defaultParams.limit,
        dataId: dataId ?? '',
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags(targets),
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
        interpolateType: getInterpolateType(props.chartOption?.interpolation, props.displayStyle),
        argParam: getArgParams(props.chartType, chartDataIndex, dataId, category),
      }),
    );
  };

  return { changeApiParamsWithInterpolate };
};

export const useWidgetApi = <T extends RequestType, R extends ChartRawData>(
  props: Props,
  emit: Emit,
  options: {
    widgetApiOptions: WidgetApiOptions;
    changeApiParams?: (
      widgetChartData: WidgetChartDataWithIndex[],
    ) => Promise<Array<T & { id: string }>>;
  },
) => {
  const isReferenceWidget = computed(() => props.createType === 'reference');

  // widget api 호출시에 사용된 time 정보. detail 연계시에 사용됨
  const widgetTimePeriod = ref<RequestPeriod | null>(null);

  const { filterToSearch, filterWidgetUUIDToSearch } = storeToRefs(useFilterSearchStore());
  const { clickedWidgetInfo, clickedWidgetId } = useWidgetClickInject();
  const { isDescendantWidget } = useWidgetRelationStore();

  const { requestKey, method, defaultParams = {}, timeout = 5_000 } = options.widgetApiOptions;

  const apiParams = ref<Record<string, Array<T & { id: string }>>>({
    [requestKey]: [],
  });

  const { widgetId = '', titleOption } = props;
  const frameName = `${FRAME_NAMES.DASHBOARD.WIDGETS}/${
    titleOption?.titleText ?? ''
  } (${widgetId})`;

  const command: Omit<DashboardCommand, 'namespace'> = {
    method,
    params: {
      ...toRaw(unref(apiParams)),
      frameName,
    },
  };

  const isRepeat =
    (props.mode === 'view' || props.mode === 'edit' || props.chartType === 'ACTION_VIEW') &&
    !isReferenceWidget.value;

  const commandOptions = {
    repeatInfo: {
      isRepeat,
      timeout,
    },
  };

  const {
    isLoading,
    data: rawData,
    chartDataStatus,
    error,
    fetchData,
    resetFetchData,
    clearFetch,
    abortApiController,
  } = useDashboardCommand<R>(command, commandOptions);

  useChartDataStatus(
    convertRequestKeyType(options.widgetApiOptions.requestKey),
    convertChartType(props.chartType, props.displayStyle),
    computed(() => rawData.value),
    computed(() => chartDataStatus.value),
    computed(() => props.mode),
    emit,
  );

  const { setWidgetDataField, getWidgetDataField } = useDataFieldInject();

  watch(rawData, (newRawData) => {
    const fieldList = getWidgetDataField(props.widgetId);
    const newFieldList = getFieldList(newRawData);
    if (fieldList == null || !isEqual(fieldList, newFieldList)) {
      setWidgetDataField(props.widgetId, getFieldList(newRawData));
    }
  });

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const { globalTimePeriod, selectedGlobalVariables } = storeToRefs(useGlobalFilterStore());
  const { monitoringDashboard, dashboardIdByRouter } = storeToRefs(useDashboardViewStore());

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const defaultChangeApiParams = async (widgetChartData: WidgetChartDataWithIndex[]) => {
    return widgetChartData.map(
      ({ chartDataIndex, id, dataType, dataId, targets, seriesType, category }) => ({
        id,
        ...defaultParams,
        dataId: dataId ?? '',
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags(targets),
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
        argParam: getArgParams(props.chartType, chartDataIndex, dataId, category),
      }),
    ) as any;
  };

  const getApiKey = (id?: number) => {
    if (props.mode === 'preview') {
      return 'preview';
    }
    if (id) {
      return `dashboard_${id}`;
    }
    const { dashboardId } = monitoringDashboard.value;
    return `dashboard_${dashboardId}`;
  };

  const updateWidgetTimePeriod = (requestParam: RequestType) => {
    widgetTimePeriod.value = {
      fromTime: requestParam.fromTime,
      toTime: requestParam.toTime,
      period: requestParam.period,
    };
  };

  const callApi = async (apiOptions?: { isResetRecall?: boolean; apiKey?: string | number }) => {
    if (props.chartData?.length && props.timePeriod) {
      const chartDataWithIndex = appendChartDataIndex(props.chartData ?? []);
      const filteredChartData = chartDataWithIndex.filter(
        ChartDataFilter[props.chartType](props.createType),
      );
      let requestParam: (T & { id: string })[] = [];
      if (options.changeApiParams) {
        requestParam = await options.changeApiParams(filteredChartData);
      } else {
        apiParams.value[requestKey] = await defaultChangeApiParams(filteredChartData);
      }
      apiParams.value[requestKey] = requestParam.map((param) =>
        assignReferenceParams(param, props.createType),
      );
      const firstRequestParam = apiParams.value[requestKey].at(0);
      if (firstRequestParam) {
        updateWidgetTimePeriod(firstRequestParam);
      } else {
        widgetTimePeriod.value = null;
      }

      if (!apiParams.value[requestKey].length || !props.isInViewport) {
        return;
      }

      const { isResetRecall = true, apiKey } = apiOptions ?? {};

      if (
        isRepeat &&
        !globalTimePeriod.value.isPaused &&
        props.timePeriod !== 'calendar' &&
        resetFetchData
      ) {
        resetFetchData({
          ...toRaw(unref(apiParams.value)),
          frameName,
          isResetRecall,
          apiKey: apiKey ?? getApiKey(),
        });
      } else if (fetchData) {
        await fetchData({
          ...toRaw(unref(apiParams.value)),
          frameName,
          isResetRecall,
          apiKey: apiKey ?? getApiKey(),
        });
      }
    }
  };

  const abortApiByKey = () => {
    const apiKey = getApiKey();
    abortApiController.byKey(apiKey);
  };
  const abortApiDueToDashboardIdChange = (dashboardId?: number) => {
    if (props.mode === 'preview') {
      return;
    }
    const apiKey = getApiKey(dashboardId);
    abortApiController.exceptKey(apiKey);
  };
  const abortAllApi = () => {
    abortApiController.all();
  };

  const callApiAfterAbort = async (apiOptions?: {
    isResetRecall?: boolean;
    apiKey?: string | number;
  }) => {
    abortApiByKey();
    await callApi(apiOptions);
  };

  const errorMsg = computed<string>(() =>
    isEmpty(error.value) ? '' : getAPIErrorStatusText(error.value),
  );

  watch(
    () => props.chartData,
    async () => {
      await callApiAfterAbort();
    },
    {
      deep: true,
    },
  );

  watch(
    selectedGlobalVariables,
    async () => {
      if (isReferenceWidget.value) return;
      const useGlobalVariables = props.chartData?.some(({ targets }) =>
        targets.some((t) => t.tagType === 'globalVariable'),
      );
      if (useGlobalVariables) {
        await callApiAfterAbort();
      }
    },
    { deep: true },
  );

  watch(
    () => props.timePeriod,
    async () => {
      if (isReferenceWidget.value) return;
      if (props.timePeriod === 'calendar') {
        return;
      }

      if (isRepeat && clearFetch) {
        await clearFetch();
      }
      await callApiAfterAbort();
    },
  );

  watch(
    () => props.calendarTimeRange,
    async (timeRange) => {
      if (isReferenceWidget.value) return;
      if (props.timePeriod === 'calendar' && timeRange) {
        if (isRepeat && clearFetch) {
          await clearFetch();
        }
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  watch(globalTimePeriod, async (cur, pre) => {
    if (isReferenceWidget.value) return;
    // calendar로 user custom period인 경우 글로벌 변수가 변경되어도 api 호출하지 않음
    if (props.timePeriod === 'calendar') {
      return;
    }

    // 재생 상태에서 timePeriod가 변경되거나 정지 상태에서 글로벌 타임으로 설정되고 fromTime, toTime 이 변경된 경우
    if (
      (!cur.isPaused && cur.timePeriod !== pre.timePeriod) ||
      (props.timePeriod === 'globalTime' && pre.isPaused && cur.isPaused)
    ) {
      await callApiAfterAbort();
      return;
    }
    // 재생중에 정지하거나 정지중에 재생할 때
    if (isRepeat && clearFetch && cur.isPaused !== pre.isPaused) {
      await clearFetch();
      if (!cur.isPaused || (props.timePeriod === 'globalTime' && cur.toTime !== pre.toTime)) {
        await callApiAfterAbort();
      }
    }
  });

  watch(dashboardIdByRouter, (id) => {
    if (id === 'all') {
      abortAllApi();
    } else {
      abortApiDueToDashboardIdChange(+id);
    }
  });

  watch(
    () => props.createType,
    async () => {
      await callApiAfterAbort();
    },
  );

  watch(
    () => props.isInViewport,
    (isInViewport) => {
      if (isInViewport) {
        callApi();
      } else {
        clearFetch?.();
      }
    },
  );

  watch(
    filterToSearch,
    async () => {
      if (!isReferenceWidget.value) return;
      if (!filterWidgetUUIDToSearch.value) {
        return;
      }
      if (isDescendantWidget(filterWidgetUUIDToSearch.value, props.widgetId)) {
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  watch(
    clickedWidgetInfo,
    async (newClickedWidgetData) => {
      if (!isReferenceWidget.value) return;
      if (!newClickedWidgetData || !clickedWidgetId.value) return;
      if (isDescendantWidget(clickedWidgetId.value, props.widgetId)) {
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  onMounted(async () => {
    await callApi({ isResetRecall: false });
  });

  return {
    isLoading,
    rawData,
    errorMsg,
    chartDataStatus,
    callApiAfterAbort,
    widgetTimePeriod,
  };
};

export const useInitWidget = <R>({
  target,
  dataKey,
  callback,
  errorMsg,
}: {
  target: Ref<R>;
  dataKey: string;
  callback: (t: any, r: boolean) => boolean;
  errorMsg: Ref<string>;
}): Ref<boolean> => {
  const result = ref<boolean>(false);
  watch(
    () => target.value?.[dataKey],
    (t) => {
      result.value = callback(t, result.value);
    },
  );
  if (errorMsg) {
    watch(
      () => errorMsg.value,
      () => {
        result.value = true;
      },
    );
  }
  return result;
};

export const useMetricChartWidget = (
  props: Props,
  rawData: Ref<MetricRawData>,
  chartDataStatusInfo: Ref<WidgetChartDataStatusInfo[]>,
) => {
  const { axisStyle, legendStyle, tooltipStyle, indicatorStyle, maxTipStyle, tipStyle } =
    useEvChartStyle();

  const unitList = computed(() => rawData.value?.unitList ?? []);
  const { tooltipValueFormatter } = useEvChartTooltipFormatter(unitList);

  const store = useGlobalFilterStore();
  const { globalTimePeriod } = storeToRefs(store);

  const getTimeAxisInfoByInterval = (interval: WidgetIntervalType | undefined) => {
    switch (interval) {
      case 'I1h':
        return {
          timeFormat: 'DD HH:mm',
          interval: 'hour',
        };
      case 'I24h':
        return {
          timeFormat: 'DD HH:mm',
          interval: 'day',
        };
      case 'I10m':
      case 'I1m':
        return {
          timeFormat: 'HH:mm',
          interval: 'minute',
        };
      default:
        return {
          timeFormat: 'HH:mm:ss',
          interval: 'second',
        };
    }
  };

  const getTimeAxisInfo = (
    timePeriod: WidgetTimePeriod | undefined,
  ): { timeFormat: string; interval: string } => {
    switch (timePeriod) {
      case 'globalTime': {
        const { fromTimeUtc, toTimeUtc } = globalTimePeriod.value;
        const interval = getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(fromTimeUtc),
          toTime: +getUtcTimeFromTzTime(toTimeUtc),
        });

        return getTimeAxisInfoByInterval(interval);
      }

      case 'calendar': {
        if (props.calendarTimeRange?.length) {
          const { fromTime, toTime } = props.calendarTimeRange[0];
          const interval = getCustomPeriodInterval('', {
            fromTime: +getUtcTimeFromTzTime(fromTime),
            toTime: +getUtcTimeFromTzTime(toTime),
          });

          return getTimeAxisInfoByInterval(interval);
        }

        return getTimeAxisInfoByInterval('I5s');
      }

      case 'p30m':
      case 'p1h':
        return getTimeAxisInfoByInterval('I1m');

      case 'p6h':
      case 'p12h':
      case 'p1d':
        return getTimeAxisInfoByInterval('I10m');

      default:
      case 'p5m':
      case 'p10m':
        return getTimeAxisInfoByInterval('I5s');
    }
  };

  const getChartOption = (type: 'bar' | 'line', option?: ChartOption): ChartOption => {
    return reactive({
      type,
      width: '100%',
      height: '100%',
      title: {
        show: false,
      },
      selectItem: {
        ...tipStyle,
        use: true,
        limit: 1,
        showTextTip: true,
        tipText: 'label',
        fixedPosTop: true,
        showIndicator: true,
        useDeselectOverflow: true,
        useSeriesOpacity: false,
        useLabelOpacity: false,
      },
      legend: {
        ...legendStyle,
        show: props.chartOption?.showLegend ?? false,
        position: 'bottom',
        padding: { top: 0, left: 30 },
        height: 30,
      },
      padding: {
        top: 20,
        right: 0,
        left: 0,
        bottom: 4,
      },
      axesX: computed<Axes[]>(() => {
        const labels = rawData.value?.chartData.labels;
        const fromTime = labels[0];
        const toTime = labels[labels.length - 1];
        const { timeFormat, interval } =
          props.createType === 'base'
            ? getTimeAxisInfo(props.timePeriod)
            : getTimeAxisInfoByInterval(
                getCustomPeriodInterval('', {
                  fromTime: +getUtcTimeFromTzTime(fromTime),
                  toTime: +getUtcTimeFromTzTime(toTime),
                }),
              );
        return [
          {
            ...axisStyle,
            type: 'time',
            timeFormat,
            interval,
            showAxis: true,
            showGrid: false,
          },
        ];
      }),
      axesY: [
        {
          ...axisStyle,
          type: 'linear',
          showGrid: true,
          showAxis: false,
          startToZero: true,
          autoScaleRatio: 0.1,
        },
      ],
      indicator: {
        ...indicatorStyle,
      },
      tooltip: {
        ...tooltipStyle,
        use: true,
        formatter: tooltipValueFormatter,
      },
      maxTip: {
        use: props.chartOption.useMarkers && props.chartOption.showMaxValue,
        tipStyle: {
          ...maxTipStyle,
        },
      },
      ...option,
    });
  };

  const getMetricChartSeries = (defaultSeriesData: SeriesData = {}): Record<string, SeriesData> => {
    const series: Record<string, SeriesData> = {};
    const data = rawData.value;

    let colorIndex = 0;

    props.chartData
      ?.filter((item) => isChartDataValid(props.createType)(item))
      .forEach(({ alias, value, seriesType, dataId, id }, index: number) => {
        const rawSeries = data?.chartData?.series;
        const statInfo = getChartDataStatusById(chartDataStatusInfo.value, id);
        if (statInfo?.status === 'success') {
          const isNoData = statInfo.info === 'NO_DATA';
          if (
            props.chartType !== WIDGET_CHART_TYPE_KEY.SCATTER &&
            seriesType === BY_TARGET_SERIES_TYPE &&
            !isNoData
          ) {
            const targets = data?.targets?.[index] ?? [];
            const colorList =
              props.colorTheme && props.colorTheme !== CUSTOM_KEY
                ? getSafeColorListByTheme(props.colorTheme)
                : CHART_COLORS_SPARROW;
            targets.forEach(({ targetName }, targetIdx) => {
              const color = colorList[colorIndex % colorList.length];
              const targetSeriesId = `${dataId}_${index}_${targetIdx}`;
              series[targetSeriesId] = {
                ...defaultSeriesData,
                name: targetName,
                color,
                pointFill: color,
              };

              colorIndex += 1;
            });
            return;
          }
          const colorList =
            props.colorTheme && props.colorTheme !== CUSTOM_KEY
              ? getSafeColorListByTheme(props.colorTheme)
              : [];
          const color = colorList?.length ? colorList[colorIndex % colorList.length] : value.color;
          const dataSeriesId = `${dataId}_${index}`;
          const dataSeriesName = rawSeries?.[dataSeriesId]?.name ?? '';
          series[dataSeriesId] = {
            ...defaultSeriesData,
            name: isNoData ? 'No Data' : alias || dataSeriesName,
            color,
            overflowColor: color,
          };
          colorIndex += 1;
        }
      });

    return series;
  };

  return {
    getChartOption,
    getMetricChartSeries,
  };
};

export const useCurrentChartWidget = (props: Props, emit: Emit) => {
  const widgetApiOptions: WidgetApiOptions = {
    requestKey: 'currentRequests',
    method: 'current',
  };

  const { isLoading, rawData, chartDataStatus, errorMsg } = useWidgetApi<
    CurrentV7Request,
    CurrentRawData
  >(props, emit, { widgetApiOptions });

  const getCurrentDataList = (): CurrentData[] => {
    const statInfoStore = useStatInfoStore();

    const currentDataList: CurrentData[] = [];
    const errorOccurredChartDataIdList = chartDataStatus.value
      .filter((statusInfo) => statusInfo.status === 'fail')
      .map((errorInfo) => errorInfo.chartDataId);

    let colorIndex = 0;

    props.chartData
      ?.filter((item) => isChartDataValid(props.createType)(item))
      .forEach(({ seriesType, category, dataId, alias, value, id }, index: number) => {
        if (!errorOccurredChartDataIdList.includes(id)) {
          const currentItems = rawData.value?.currentData?.[index];

          const statName = statInfoStore.getStatInfo({
            childCategory: category,
            statId: dataId,
          })?.name;

          if (seriesType === BY_TARGET_SERIES_TYPE) {
            const colorList =
              props.colorTheme && props.colorTheme !== CUSTOM_KEY
                ? getSafeColorListByTheme(props.colorTheme)
                : CHART_COLORS_SPARROW;
            const targets = rawData.value?.targets?.[index] ?? [];

            targets.forEach(({ targetId, targetName }, targetIndex: number) => {
              const { unit = '', value: itemValue = 0 } =
                currentItems?.find((item) => item.targetId === targetId) ?? {};

              const color = colorList[colorIndex % colorList.length];

              currentDataList.push({
                id: `${index}_${targetIndex}`,
                chartDataIndex: index,
                label: targetName,
                value: itemValue,
                color,
                unit: !unit || unit?.toLowerCase() === 'count' ? '' : unit,
                statName: statName || '',
                alias: alias || '',
              });
              colorIndex += 1;
            });
            return;
          }
          let { color } = value;
          if (props.colorTheme !== CUSTOM_KEY && props.colorTheme) {
            color = getSafeColorListByTheme(props.colorTheme)[index];
          } else {
            color = color ?? getDefaultSeriesColor(index);
          }
          const unit = currentItems?.[0]?.unit;

          currentDataList.push({
            id: `${dataId}_${index}`,
            chartDataIndex: index,
            label: alias || statName || '',
            value: currentItems?.[0]?.value ?? 0,
            color,
            unit: !unit || unit?.toLowerCase() === 'count' ? '' : unit,
            statName: statName || '',
            alias: alias || '',
          });
        }
      });
    return currentDataList;
  };

  return {
    isLoading,
    rawData,
    errorMsg,
    getCurrentDataList,
  };
};

export const useEqualizerChartWidget = (props: Props, emit: Emit) => {
  const equalizerChartRef = ref();
  const chartData = ref<EqualizerChartData[]>([]);

  const widgetApiOptions: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    if (widgetChartData.length === 0) {
      return [];
    }

    const { getFilteredTags, getRequestPeriod } = useWidgetDataParams();
    const { id, dataType, dataId, targets, seriesType, category, chartDataIndex } =
      widgetChartData[0];
    return [
      {
        id,
        dataId,
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags(targets),
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
        argParam: getArgParams(props.chartType, chartDataIndex, dataId, category),
      },
    ];
  };

  const { isLoading, rawData, chartDataStatus, errorMsg } = useWidgetApi<
    TableV7Request,
    TableRawData
  >(props, emit, { widgetApiOptions, changeApiParams });

  const chartOptionTableData = computed<EqualizerChartOptionTableData[]>(
    () => props.chartOption.tableData ?? [],
  );

  // targetId가 있고 number type이 있는 경우
  const isValidData = (columnInfos: ColumnInfo[]) => {
    const isExistTargetInfo = columnInfos.some(
      (columnInfo) => columnInfo.dataAttribute === 'TargetId',
    );
    return isExistTargetInfo && columnInfos.some((columnInfo) => columnInfo.dataType === 'Number');
  };

  const getTargetInfoIndex = (
    columnInfos: ColumnInfo[],
  ): { targetId: number; targetName: number } => {
    return columnInfos.reduce(
      (acc: { targetId: number; targetName: number }, columnInfo, index) => {
        if (columnInfo.dataAttribute === 'TargetId') {
          acc.targetId = index;
        } else if (columnInfo.dataAttribute === 'TargetName') {
          acc.targetName = index;
        }
        return acc;
      },
      {
        targetId: -1,
        targetName: -1,
      },
    );
  };

  const filteredRawData = computed<
    Omit<TableRawData, 'targets'> & { targets: TargetTriggerInfo[][] }
  >(() => {
    const filteredTargets: TargetTriggerInfo[][] = [];
    const filteredTableData: TableRawData['tableData'] = [];

    const { tableData } = rawData.value ?? { tableData: [], targets: [] };

    tableData.forEach((tableDataInfo) => {
      if (isValidData(tableDataInfo.columns)) {
        const targetInfoIdx = getTargetInfoIndex(tableDataInfo.columns);
        const triggerPath =
          (tableDataInfo.columns[targetInfoIdx.targetId]?.triggerHandler?.[0]
            ?.triggerPath as WidgetTriggerPathType) ?? null;
        const targets: TargetTriggerInfo[] = tableDataInfo.rows.map((row) => ({
          targetId: row[targetInfoIdx.targetId] ? String(row[targetInfoIdx.targetId]) : '',
          targetName: row[targetInfoIdx.targetName] ? String(row[targetInfoIdx.targetName]) : '',
          triggerPath,
        }));
        filteredTargets.push(targets);
        filteredTableData.push(tableDataInfo);
      }
    });

    return { targets: filteredTargets, tableData: filteredTableData, chartDataStatus };
  });

  // 모든 target에 대한 fieldNameList(targetId, targetName, status 제외) unique한 array로 추출
  const fieldInfoList = computed<
    {
      name: string;
      unit: string;
    }[]
  >(() =>
    uniqBy(
      filteredRawData.value?.tableData
        .map(({ columns }) => {
          return columns
            .filter(
              ({ fieldName, dataType, dataAttribute }) =>
                dataAttribute !== 'TargetId' &&
                dataAttribute !== 'TargetName' &&
                fieldName !== 'status' &&
                dataType === 'Number',
            )
            .map((column) => ({
              name: column.name,
              unit: column.unit,
            }));
        })
        .flat(),
      'name',
    ),
  );

  const targetInfoMap = computed<Map<string, string>>(() => {
    const infoMap = new Map();
    filteredRawData.value.targets.forEach((targetList) => {
      targetList.forEach((targetInfo) => {
        infoMap.set(targetInfo.targetId, targetInfo.targetName || targetInfo.targetId);
      });
    });
    return infoMap;
  });

  // Record<targetId, Record<fieldName, number> & { status: MonitoringTargetStatus }>[]
  const extractValueByFieldNameAndTarget = (
    data: TableRawData,
  ): Record<string, Record<string, number> & { status: MonitoringTargetStatus }>[] => {
    const temp: Record<string, Record<string, number> & { status: MonitoringTargetStatus }>[] = [];

    data.targets.forEach((statTargetList, index) => {
      const valueByFieldNameAndTarget: Record<
        string,
        Record<string, number> & { status: MonitoringTargetStatus }
      > = {};
      statTargetList.forEach((statTarget) => {
        const { columns, rows } = data.tableData[index];

        const targetIdIdx = columns.findIndex(({ dataAttribute }) => dataAttribute === 'TargetId');
        const rowData = rows.find((row) => row[targetIdIdx] === statTarget.targetId);

        if (rowData) {
          valueByFieldNameAndTarget[statTarget.targetId] = columns.reduce((acc, cur, idx) => {
            if (
              cur.dataAttribute !== 'TargetId' &&
              cur.dataAttribute !== 'TargetName' &&
              cur.fieldName !== 'status' &&
              cur.dataType === 'Number'
            ) {
              acc[cur.name] = rowData[idx];
            }
            return acc;
          }, {}) as Record<string, number> & { status: MonitoringTargetStatus };
        }
      });
      temp[index] = valueByFieldNameAndTarget;
    });
    return temp;
  };

  // chartOption에서 변경한 fieldName 순서에 따라 equalizer chart data 순서 변경시킨 array 추출하는 함수
  const extractValuesByFieldNameOrder = (
    nameOrder: string[],
    valueObj: { [key: string]: number },
  ) => {
    return nameOrder.reduce<number[]>((acc, cur) => {
      acc.push(valueObj[cur] ?? 0);
      return acc;
    }, []);
  };

  const getCurrentEqualizerChartData = (
    data: Record<string, Record<string, number> & { status: MonitoringTargetStatus }>[],
  ): EqualizerChartData[] => {
    const temp: EqualizerChartData[] = [];

    data.forEach((valueByFieldNameAndTarget) => {
      Object.entries(valueByFieldNameAndTarget).forEach((obj) => {
        const [key, value] = obj;

        temp.push({
          label: targetInfoMap.value.get(key) || key,
          values: extractValuesByFieldNameOrder(
            chartOptionTableData.value
              .filter((option) => option.showInfo.isShow)
              .map((option) => option.name),
            value,
          ),
          status: value.status || 'active',
        });
      });
    });

    return temp;
  };

  const valueByFieldNameAndTarget = computed<
    Record<string, Record<string, number> & { status: MonitoringTargetStatus }>[]
  >(() => {
    return filteredRawData.value ? extractValueByFieldNameAndTarget(filteredRawData.value) : [];
  });

  const sortedData = computed<{ label: string; values: number[] }[]>(() => {
    return getCurrentEqualizerChartData(valueByFieldNameAndTarget.value);
  });

  const isInit = useInitWidget<TableRawData>({
    target: rawData,
    dataKey: 'tableData',
    callback: (_, value) => {
      chartData.value = sortedData.value;

      return value || (!value && (props.mode === 'view' || props.mode === 'edit'));
    },
    errorMsg,
  });

  const chartSeries = computed<EqualizerChartSeriesData[]>(() => {
    const colorThemeList =
      props.colorTheme && props.colorTheme !== CUSTOM_KEY
        ? getSafeColorListByTheme(props.colorTheme)
        : [];

    return chartOptionTableData.value
      .filter((data) => data.showInfo.isShow)
      .map((data, index) => {
        return {
          label: data.alias.value ? data.alias.value : data.name,
          color:
            props.colorTheme !== CUSTOM_KEY
              ? colorThemeList[index % colorThemeList.length]
              : data.colorInfo.color,
          unit: data.unit,
        };
      });
  });

  watch(sortedData, () => {
    chartData.value = sortedData.value;
  });

  const colorList = getDefaultSeriesColors();

  watch(rawData, () => {
    emit('update:chartOption', {
      ...props.chartOption,
      tableData: fieldInfoList.value.map(({ name, unit }, index) => {
        const existOption = chartOptionTableData.value.find(
          (option) => option.name === name && option.unit === unit,
        );
        return (
          existOption ?? {
            id: generateUUID(),
            name,
            alias: {
              value: '',
            },
            colorInfo: {
              color: colorList[index % colorList.length],
            },
            showInfo: {
              isShow: true,
            },
            unit,
          }
        );
      }),
    });
  });

  const dashboardViewStore = useDashboardViewStore();
  const { setSelectedDetailInfo, initSelectedDetailInfo } = dashboardViewStore;
  const { selectedDetailInfo } = storeToRefs(dashboardViewStore);
  const clickedDetailData = ref<TriggerDetailInfo | null>(null);

  const onClick = (value: EqualizerClickEventValue | null) => {
    if (props.mode === 'view') {
      const targetInfo = filteredRawData.value.targets?.[0]?.[value?.index ?? -1];
      if (
        clickedDetailData.value?.widgetId === props.widgetId &&
        clickedDetailData.value?.targetId === targetInfo?.targetId
      ) {
        clickedDetailData.value = null;
        equalizerChartRef.value?.initChart();
        initSelectedDetailInfo();
      } else if (targetInfo?.triggerPath) {
        clickedDetailData.value = {
          widgetId: props.widgetId!,
          triggerPath: targetInfo?.triggerPath,
          targetId: targetInfo?.targetId,
          targetName: targetInfo?.targetName,
        };
        setSelectedDetailInfo({ ...clickedDetailData.value });
      }
    }
  };

  watch(
    () => selectedDetailInfo.value,
    (detailInfo) => {
      if (clickedDetailData.value && detailInfo?.widgetId !== props.widgetId) {
        clickedDetailData.value = null;
        equalizerChartRef.value?.initChart();
      }
    },
  );

  return { equalizerChartRef, isLoading, isInit, chartData, errorMsg, chartSeries, onClick };
};

export const useStatusSummaryWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(({ id, dataType, targets, seriesType, category, value }) => ({
      id,
      dataId: FIXED_DATA_ID.STATUS_SUMMARY,
      category,
      aggregationType: seriesType,
      summaryType: dataType,
      ...getFilteredTags(targets),
      ...getRequestPeriod(props.timePeriod),
      targetCategory: value?.targetCategory ?? category,
    }));
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
  };
};

export const useStatusHexaWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(({ id, dataType, targets, seriesType, category, value }) => ({
      id,
      dataId: FIXED_DATA_ID.STATUS,
      category,
      aggregationType: seriesType,
      summaryType: dataType,
      ...getFilteredTags(targets),
      ...getRequestPeriod(props.timePeriod),
      targetCategory: value?.targetCategory ?? category,
      sortColumn: value?.sort,
      sortType: value?.orderBy,
      limit: value?.limit,
    }));
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
  };
};

export const useGaugeChartWidget = (props: GaugeWidgetProps, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'currentRequests',
    method: 'current',
  };

  const { getFilteredTags, getRequestPeriod } = useWidgetDataParams();
  const { getArgParams } = useWidgetArgParam(computed(() => ({})));

  const multiData = computed(() => props.chartOption.multiGauge?.chartData);
  const markerData = computed(() => props.chartOption.marker?.markerValueOption?.chartData);

  const widgetApi = useWidgetApi<CurrentV7Request, CurrentRawData>(props, emit, {
    widgetApiOptions: options,
    changeApiParams: async (
      widgetChartData: WidgetChartDataWithIndex[],
    ): Promise<(TableV7Request & { id: string })[]> => {
      const req = [
        ...widgetChartData,
        props.chartOption.multiGauge?.use
          ? {
              ...multiData.value,
              seriesType: widgetChartData?.[0]?.seriesType,
              targets: widgetChartData?.[0]?.targets,
            }
          : {},
        props.chartOption.marker?.markerValueOption?.type === 'metricMarker'
          ? {
              ...markerData.value,
              targets: widgetChartData?.[0]?.targets,
            }
          : {},
      ].filter((data): data is WidgetChartDataWithIndex => !!data);

      const chartDataWithIndex = appendChartDataIndex(req ?? []);
      const filteredChartData = chartDataWithIndex.filter(
        ChartDataFilter[props.chartType](props.createType),
      );

      return filteredChartData.map(({ id, dataType, dataId, targets, seriesType, category }) => ({
        id,
        dataId,
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags(targets),
        ...getRequestPeriod(props.timePeriod),
        argParam: getArgParams(props.chartType, 0, dataId, category),
      }));
    },
  });

  watch(
    [
      () => multiData.value?.dataType,
      () => multiData.value?.dataId,
      () => markerData.value?.dataId,
      () => markerData.value?.dataType,
      () => markerData.value?.seriesType,
      () => props.chartOption.multiGauge?.use,
      () => props.chartOption.marker?.markerValueOption?.type,
    ],
    async () => {
      await widgetApi.callApiAfterAbort();
    },
  );

  return {
    ...widgetApi,
  };
};

export const usePieChartWidget = (props: PieWidgetProps, emit: Emit) => {
  const chartData = ref<PieChartItem[]>([]);

  const widgetApiOptions: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getFilteredTags, getRequestPeriod } = useWidgetDataParams();

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    let tableRequests: (TableV7Request & { id: string })[] = [];

    if (widgetChartData.length) {
      tableRequests = widgetChartData.map(
        ({ id, dataType, dataId, targets, seriesType, category }) => ({
          id,
          dataId: dataId ?? '',
          category,
          aggregationType: seriesType,
          summaryType: dataType,
          ...getFilteredTags(targets),
          ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
          argParam: getArgParams(props.chartType, 0, dataId, category),
        }),
      );

      const sortByFieldName = props.chartOption?.sortByFieldName;
      const orderBy = props.chartOption?.orderBy ?? DefaultPieWidgetChartOption.orderBy;
      const limitNum = props.chartOption?.limitNum ?? DefaultPieWidgetChartOption.limitNum;

      tableRequests[0] = {
        ...tableRequests[0],
        sortType: orderBy || undefined,
        limit: limitNum,
        sortColumn: sortByFieldName || undefined,
      };
    }
    return tableRequests;
  };

  const { isLoading, rawData, errorMsg, callApiAfterAbort } = useWidgetApi<
    TableV7Request,
    TableRawData
  >(props, emit, { widgetApiOptions, changeApiParams });

  const convertToPieChartData = (
    { tableData }: TableRawData,
    displayName: string,
    displayValue: string,
  ): PieChartItem[] => {
    return tableData.map(({ columns, rows }) => {
      const nameIndex =
        columns.findIndex((column) => (column.fieldName || column.name) === displayName) ?? -1;
      const valueIndex =
        columns.findIndex((column) => (column.fieldName || column.name) === displayValue) ?? -1;
      const valueUnit = columns[valueIndex]?.unit ?? '';

      if (nameIndex > -1 && valueIndex > -1) {
        const sumRowData = rows.reduce<[string, number][]>((sumData, row) => {
          const index = sumData.findIndex((data) => `${data[0]}` === `${row[nameIndex]}`);
          if (index > -1) {
            sumData[index] = [`${row[nameIndex]}`, (sumData[index][1] ?? 0) + +row[valueIndex]];
          } else {
            sumData.push([`${row[nameIndex]}`, +row[valueIndex]]);
          }
          return sumData;
        }, []);
        return {
          data: sumRowData.map(([name, value]) => ({ name, value, unit: valueUnit })),
        };
      }

      return { data: [] };
    });
  };

  const isInit = useInitWidget<TableRawData>({
    target: rawData,
    dataKey: 'tableData',
    callback: (_, value) => {
      chartData.value = convertToPieChartData(
        rawData.value,
        props.chartOption?.displayName ?? '',
        props.chartOption?.displayValue ?? '',
      );

      return value || (!value && (props.mode === 'view' || props.mode === 'edit'));
    },
    errorMsg,
  });

  watch(
    rawData,
    (newRawData) => {
      if (props.mode === 'preview') {
        emit('update:chartOption', {
          ...props.chartOption,
          legendStatus: undefined,
          fieldNameList: newRawData?.tableData.at(0)?.columns.map((column) => {
            return {
              name: column.name,
              value: column.fieldName ?? column.name ?? '',
              dataType: column.dataType,
            };
          }),
        });
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    () => props.chartOption,
    (newChartOption) => {
      if (rawData.value && newChartOption?.displayName && newChartOption.displayValue) {
        chartData.value = convertToPieChartData(
          rawData.value,
          newChartOption.displayName,
          newChartOption.displayValue,
        );
      } else {
        chartData.value = [];
      }
    },
    {
      deep: true,
    },
  );

  watch(
    [
      () => props.chartOption?.sortByFieldName,
      () => props.chartOption?.limitNum,
      () => props.chartOption?.orderBy,
    ],
    (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        callApiAfterAbort();
      }
    },
  );

  return { chartData, isInit, isLoading, errorMsg };
};

export const getTimeSeriesChartMaxData = (data: ChartData['data'] = {}) => {
  const values = Object.values(data).flat();
  if (values.length < 1) return 0;
  return Math.max(...values);
};

export const useChartYAxisUnit = (
  unitList: MaybeRefOrGetter<string[]>,
  yMaxValue: MaybeRefOrGetter<number>,
) => {
  const isUnitUnique = computed(() => uniq(toValue(unitList)).length === 1);
  const uniqueUnit = computed(() => (isUnitUnique.value ? toValue(unitList)[0] : ''));

  const yMaxValueUnit = computed(
    () =>
      convertValueWithUnit({
        value: toValue(yMaxValue),
        unit: uniqueUnit.value,
      }).unit,
  );

  const yAxisFormatter = (value: number) => {
    return formatYAxisWithUnit({
      value,
      unit: isUnitUnique.value ? uniqueUnit.value : 'count',
      showUnit: false,
      toUnit: yMaxValueUnit.value,
    });
  };

  const assignYAxisFormatter = (chartOption: ChartOption) => {
    return {
      ...chartOption,
      axesY: chartOption.axesY?.map((yAxisOption, index) => {
        const unit = toValue(unitList)[index];

        return {
          ...yAxisOption,
          formatter: unit ? yAxisFormatter : undefined,
        };
      }),
    };
  };

  return { uniqueUnit: yMaxValueUnit, assignYAxisFormatter, yAxisFormatter };
};

export const useTopologyWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'topologyRequests',
    method: 'topology',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TopologyV7Request & { id: string })[]> => {
    const nodes: Nodes[] =
      widgetChartData.map(({ targets, value }) => ({
        ...value,
        ...getFilteredTags(targets),
      })) ?? [];

    return [
      {
        id: widgetChartData.at(0)?.id ?? '',
        dataId: FIXED_DATA_ID.TOPOLOGY,
        aggregationType: 'byTarget' as TopologyV7RequestAggregationType,
        summaryType: 'current',
        nodes,
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
      },
    ];
  };

  return {
    ...useWidgetApi<TopologyV7Request, TopologyRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
  };
};

export const useServerWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(({ id, dataType, targets, seriesType, category, value }) => ({
      id,
      dataId: FIXED_DATA_ID.STATUS,
      category,
      aggregationType: seriesType,
      summaryType: dataType,
      ...getFilteredTags(targets),
      ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
      targetCategory: value?.targetCategory ?? category,
      sortColumn: value?.sort,
      sortType: value?.orderBy,
      limit: value?.limit,
    }));
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
  };
};

export const useWidgetDynamicColumns = (
  widgetId: MaybeRefOrGetter<string>,
  widgetMode: MaybeRefOrGetter<WidgetModeType>,
  widgetChartType: MaybeRefOrGetter<Extract<WidgetChartType, 'TABLE' | 'TREE_GRID'>>,
  envKeys: {
    global: DashboardUserEnvKeys;
    custom: DashboardUserEnvKeys;
  },
) => {
  const dashboardUserEnvStore = useDashboardUserEnvStore();
  const userEnvStore = useUserEnvStore();

  const { getWidgetUserEnvKey } = useDashboardUserEnv();

  const userEnvKeyGlobal = getWidgetUserEnvKey(envKeys.global, toValue(widgetId));
  const userEnvKeyCustom = getWidgetUserEnvKey(envKeys.custom, toValue(widgetId));

  const getEnvData = () => {
    const globalUserEnvData = userEnvStore.getEnvValue(userEnvKeyGlobal);

    const editingUserEnvData = dashboardUserEnvStore.getEditingUserEnvData(
      userEnvKeyGlobal,
      toValue(widgetChartType),
    );
    const previewUserEnvData = dashboardUserEnvStore.getPreviewUserEnvData(
      userEnvKeyGlobal,
      toValue(widgetChartType),
    );

    switch (toValue(widgetMode)) {
      case 'edit':
        return editingUserEnvData ?? globalUserEnvData;
      case 'preview':
        return previewUserEnvData ?? editingUserEnvData ?? globalUserEnvData;
      default:
        return userEnvStore.getEnvValue(userEnvKeyCustom);
    }
  };

  const {
    applyChangeColumnInfo: applyChangeGlobalColumnInfo,
    makeColumnsAndRows: makeGlobalColumnsAndRows,
  } = useDynamicColumns();
  const {
    applyChangeColumnInfo: applyChangeCustomColumnInfo,
    makeColumnsAndRows: makeCustomColumnsAndRows,
  } = useDynamicColumns();

  const updateDynamicColumn = (
    dynamicColumns: CustomColumn[],
    rowData: Record<string, unknown>[],
    option?: {
      defaultColumn?: CustomColumn[];
    },
  ) => {
    const dynamicColumnData = [
      {
        columns: dynamicColumns.map(({ field }) => field),
        rowData,
      },
    ];

    const envCustomValue = userEnvStore.getEnvValue(userEnvKeyCustom);

    if (!!envCustomValue && toValue(widgetMode) === 'view') {
      return makeCustomColumnsAndRows(dynamicColumnData, dynamicColumns, {
        columnEnvKey: userEnvKeyCustom,
        defaultColumns: option?.defaultColumn,
      });
    }
    return makeGlobalColumnsAndRows(dynamicColumnData, dynamicColumns, {
      columnEnvKey: userEnvKeyGlobal,
      defaultColumns: option?.defaultColumn,
    });
  };

  const onChangeColumnInfo = async ({
    columns: changedColumns,
  }: {
    columns: ColumnWithHideInfo[];
  }) => {
    if (toValue(widgetMode) === 'edit') {
      dashboardUserEnvStore.setEditingUserEnvData(userEnvKeyGlobal, {
        chartType: toValue(widgetChartType),
        global: true,
        value: changedColumns,
      });
      dashboardUserEnvStore.setEditingUserEnvData(userEnvKeyCustom, {
        chartType: toValue(widgetChartType),
        global: false,
        value: changedColumns,
      });
    } else if (toValue(widgetMode) === 'preview') {
      dashboardUserEnvStore.setPreviewUserEnvData({
        key: userEnvKeyGlobal,
        value: { chartType: toValue(widgetChartType), value: changedColumns },
      });
    }
  };

  return {
    userEnvKeyGlobal,
    userEnvKeyCustom,
    getEnvData,
    applyChangeGlobalColumnInfo,
    applyChangeCustomColumnInfo,
    updateDynamicColumn,
    onChangeColumnInfo,
  };
};
